import React from "react"
import Masonry from "react-masonry-css"

export default ({
  children,
  className = ""
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <Masonry
      breakpointCols={{
        default: 3,
        960: 2,
        620: 1
      }}
      className={["masonry-grid", className].join(" ")}
      columnClassName="masonry-column">
        {children}
    </Masonry>
  )
}
