import React from "react"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import Card from "~/components/Card"
import Box from "~/components/Box"
// import AOS from "aos"
import Picture from "../Picture";

export default React.memo(({
  title, featuredImage,
}: {
  id: string
  uri: string
  title: string
  featuredImage: {
    node: {
      sourceUrl: string
      srcSet: string
      sizes: string
      mimeType: string
      localFile: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData
        }
      }
    }
  }
  index: number
}) => {

  React.useEffect(() => {
    //AOS.refresh()
  }, [

  ])

  const image = featuredImage?.node

  return (
    <Box
      className="is-square"
      background={image && (
        image.localFile?.childImageSharp?.gatsbyImageData ? (
          <GatsbyImage
            image={image.localFile?.childImageSharp?.gatsbyImageData}
            alt={title}
          />
        ) : (
          <div className="is-responsive-1-1">
            <Picture src={image.sourceUrl} alt="" sources={[
              image
            ]} />
          </div>
        )
      )}
    >
      <Card className="is-squared-card is-align-items-center is-text-align-center has-text-shadow">
        <h3>
          {title}
        </h3>
      </Card>
    </Box>
  );
})
