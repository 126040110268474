import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { PageProps, graphql, Link } from "gatsby"
import Grid from "~/components/Grid/Auto"
import useScrollToTop from "~/hooks/useScrollToTop"
import useMaxWidth from "~/hooks/useMaxWidth"
import LazyLoad from "react-lazyload"
import AOS from "aos"
import Squared from "~/components/Squared"
import Animated from "~/components/Animated"
import decode from "~/functions/decode"
import { I18nContext } from "~/components/I18n"
import { IGatsbyImageData } from "gatsby-plugin-image"

export const query = graphql`{
  site {
    siteMetadata {
      title
      description
      author
      image
      siteUrl
    }
  }
  wpMenuItem(url: {regex: "/^\\/people/"}) {
    id
    label
  }
  allWpInfluencercategory(filter: {count: {gt: 0}}) {
    edges {
      node {
        id
        uri
        name
        language {
          slug
        }
      }
    }
  }
  allWpInfluencer(
    sort: {
      order: ASC,
      fields: title
    }
    filter: {
      status: {
        in: ["publish", "future"]
      }
    }
  ) {
    edges {
      node {
        id
        uri
        date
        title
        categories {
          categories {
            id
          }
        }
        language {
          slug
        }
        featuredImage {
          node {
            sourceUrl
            srcSet
            sizes
            mimeType
            localFile {
              childImageSharp {
                gatsbyImageData(width: 650, height: 650, quality: 92, layout: CONSTRAINED, transformOptions: { fit: COVER, cropFocus: ATTENTION })
              }
            }
          }
        }
      }
    }
    distinct(field: id)
  }
}
`

export default ({
  data: {
    allWpInfluencercategory,
    allWpInfluencer,
    wpMenuItem: menuItem,
    site: {
      siteMetadata: {
        title,
        description,
        image,
        author,
        siteUrl
      },
    },
  },
}: PageProps<{
  site: {
    siteMetadata: {
      title: string
      description: string
      image: string
      author: string
      siteUrl: string
      apiUrl: string
    }
  }
  wpMenuItem?: {
    id: string
    label: string
  },
  allWpInfluencercategory: {
    edges: {
      node: {
        id: string
        uri: string
        name: string
        language: {
          slug: string
        }
      }
    }[]
  }
  allWpInfluencer: {
    edges: {
      node: {
        id: string
        uri: string
        date: string
        title: string
        categories: {
          categories: {
            id: string
          }[]
        }
        language: {
          slug: string
        }
        featuredImage: {
          node: {
            sourceUrl: string
            srcSet: string
            sizes: string
            mimeType: string
            localFile: {
              childImageSharp: {
                gatsbyImageData: IGatsbyImageData
              }
            }
          }
        }
      }
    }[]
  }
}>) => {
  useScrollToTop()
  useMaxWidth()

  React.useEffect(() => {
    AOS.refresh()
  })

  const { locale } = useContext(I18nContext)

  return (
    <>
      <Helmet
        defer={false}
        htmlAttributes={{
          lang: locale,
          dir: "ltr",
          prefix: "og: http://ogp.me/ns#",
        }}
      >
        <title>{title}</title>
        <link rel="author" href="/humans.txt" />

        <meta name="description" content={description} />
        <meta name="image" content={`${siteUrl}${image}`} />

        <meta property="og:title" content={title} />
        <meta property="og:locale" content={locale} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={`${siteUrl}${image}`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={author} />
        <meta name="twitter:creator" content={author} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={`${siteUrl}${image}`} />


      </Helmet>

      <header className="is-uppercase has-margin-bottom">
        <h1>{menuItem?.label || "Influencers"}</h1>

        {/* <FilterNav>
          {React.Children.toArray(allWpInfluencercategory.edges
          .filter((l) => l.node.language.slug === locale)
          .map(({
            node: {
              id, uri, name
            }
          }) => {
            return (
              <Link
                id={id}
                to={uri}
                key={id}
                title={name}
              >{name}</Link>
            )
          }))}
        </FilterNav> */}
      </header>

      <section>
        <Grid className="is-square has-no-gaps is-fixed-colors">
          {React.Children.toArray(allWpInfluencer.edges
            .filter(({ node: { date, language } }) => (
              new Date(date) <= new Date() && locale === language.slug
            ))
            .map(({
              node: {
                id, uri, title, featuredImage
              }
            }, i) => (
              <LazyLoad key={id}>
                <Animated
                  duration={`500ms`}
                  delay={`${150 * i}ms`}
                >
                  <Link to={uri}>
                    <Squared
                      id={id}
                      uri={uri}
                      title={decode(title)}
                      featuredImage={featuredImage}
                      index={i}
                    />
                  </Link>
                </Animated>
              </LazyLoad>
            )
            ))}
        </Grid>
      </section>
    </>
  )
}
