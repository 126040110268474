import React from "react"
import Masonry from "./Masonry"

export default ({
  children,
  className = ""
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <div className={["is-auto-grid", className].join(" ")}>
      {children}
    </div>
  )
}

export {
  Masonry
}
